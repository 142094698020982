import {maxLength, minLength, required, sameAs} from 'vuelidate/lib/validators'

class userRules {
  passObj = {
    passObj: {
      current_password: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(20)
      },
      new_password1: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(20)
      },
      new_password2: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(20),
        sameAs: sameAs('new_password1')
      }
    }
  }
}

export const USER_RULES = new userRules ()