import { api } from '@/mixins/api'
import { notify } from '@/mixins/notify'

export const BASE = {
  state: () => ({
    carBrand: [],
    vehicleType: [],
    colors: [],
    fuelType: []
  }),
  mutations: {
    setStateData (state, data) {
      state[data.type] = []
      state[data.type] = data.data
    },
    REMOVE_DATA_ITEM (state, data) {
      state[data.type] = state[data.type].filter((item) => item.uid !== data.data)
    },
    REMOVE_CAR_MODEL (state, {uid, indexBrand}) {
      state.carBrand[indexBrand].models = state.carBrand[indexBrand].models.filter(item => item.uid !== uid)
    },
    SET_DATA (state, data) {
      state[Object.entries(data)[0][0]] = Object.entries(data)[0][1]
    },
    ADD_DATA (state, data) {
      state[Object.entries(data)[0][0]] = state[Object.entries(data)[0][0]].concat(Object.entries(data)[0][1])
    }
  },
  actions: {
    async getCarBrands ({ commit }) {
      await api.get('admin/manufacturer').then(response => {
        commit('setStateData', {
          type: 'carBrand',
          data: response.data
        })
      })
    },
    async getVehicleType ({ commit }) {
      await api.get('admin/vehicle-type').then(response => {
        commit('setStateData', {
          type: 'vehicleType',
          data: response.data
        })
      })
    },

    async getColors ({ commit }) {
      await api.get('admin/color').then(response => {
        commit('setStateData', {
          type: 'colors',
          data: response.data
        })
      })
    },

    async getFuelType ({ commit }) {
      await api.get('admin/fuel').then(response => {
        commit('setStateData', {
          type: 'fuelType',
          data: response.data
        })
      })
    },

    async addCarManufacturer ({ commit }, data ) {
      const body = JSON.stringify({
        name: data
      })
      await api.post('admin/manufacturer', body)
        .then(response => {
          console.log(response)
          commit('ADD_DATA', { carBrand: response.data })
        })
        .catch(err => {
          err && notify.error('Помилка серверу')
        })
    },

    async addNewCarModel ({commit}, data) {
      const body = JSON.stringify({...data})
      const commitConfig = {type: 'carBrand', data: data.manufacturer}
      await api.post(`admin/model`, body)
        .then(response => {
          if (response.status === 'delete') {
            commit('REMOVE_DATA_ITEM', commitConfig)
            return Promise.resolve()
          }
        })
        .catch(err => {
          err && notify.error('Помилка серверу')
        })
    },

    async deleteCarManufacturer ({commit}, {uid}) {
      const params = new URLSearchParams()
      params.set('uid', uid)
      const commitConfig = {type: 'carBrand', data: uid }
      await api.delete(`admin/manufacturer/?${params}`)
        .then((response) => {
          response.status === 'deleted' && commit('REMOVE_DATA_ITEM', commitConfig)
      })
      .catch(err => {
        err && notify.error('Помилка серверу')
      })
    },
    
    async deleteCarModel ({commit}, data) {
      const params = new URLSearchParams()
      params.set('uid', data.uid)
      await api.delete(`admin/model/?${params}`)
        .then((response) => {
          response.status === 'deleted' && commit('REMOVE_CAR_MODEL', data)
        })
        .catch(err => {
          err && notify.error('Помилка серверу')
        })
    }
  },
  getters: {
    carModelsList: state => id => state.carBrand.filter(brand => brand.id === id),
    validCarBrandsList: state => state.carBrand.filter(brand => brand.models && brand.models.length)
  }
}
