import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './locales/en.json'
import ua from './locales/ua.json'
import store from '@/store'

Vue.use(VueI18n)

const transitions = {
  en: en,
  ua: ua
}

const messages = Object.assign(transitions)

const i18n = new VueI18n({
  locale: store.state.main.lang,
  messages,
  silentTranslationWarn: true
})

export default i18n
