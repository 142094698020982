import Vue from 'vue'
import Vuex from 'vuex'
import { MAIN } from './modules/main'
import { USER } from './modules/user'
import { STAND } from './modules/stand'
import { BASE } from './modules/base'
import { PERMISSIONS } from './modules/permissions'
import { HISTORY } from './modules/history'
import { MEASUREMENT } from "./modules/measurement"
import { VERSION } from "./modules/version"


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    main: MAIN,
    user: USER,
    stand: STAND,
    base: BASE,
    permissions: PERMISSIONS,
    history: HISTORY,
    measurement: MEASUREMENT,
    version: VERSION
  },
})
