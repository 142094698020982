import { api } from '@/mixins/api'

export const PERMISSIONS = {
  state: () => ({
    load: false,
    permissions: {
      superadmin: false,
      admin: false,
      operator: false
    },
  }),
  mutations: {
    setPermission (state, data) {
      state.permissions[data.prop] = data.value
    },
    setLoad (state) {
      setTimeout(() => {
        state.load = true
      }, 500)
    }
  },
  actions: {
    async getPermission({ commit }) {
      let token = localStorage.getItem('Token')
      if (token) {
        api.get('stand/user_role')
          .then(response => {
            console.log(response);
            commit('setLoad', { value: false })
            switch (response.data) {
              case 'superadmin':
                return commit('setPermission', { prop: 'superadmin', value: true })
              case 'local admin':
                return commit('setPermission', { prop: 'admin', value: true })
              case 'operator':
                return commit('setPermission', { prop: 'operator', value: true })
              default:
                return commit('setLoad', { value: true })
            }
          })
          .catch(err => {
            console.log(err);
            commit('setLoad', { value: false })
          })
      } 
      if (!token && window.location.pathname === '/login') {
        commit('setLoad', { value: true })
      }
    }
  }
}
