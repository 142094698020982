<template>
  <div class="topBar">
    <v-app-bar-nav-icon class="menu-burger" @click="tooggleMenu"></v-app-bar-nav-icon>

    <div class="topBar-title">
      {{ title }}
    </div>
    <div class="topBar-actions">
      <router-link to="/versions">{{$t('guideVersion')}}</router-link>
      <router-link to="/settings">{{$t('settings')}}</router-link>
      <a href="#" @click="logOut()">{{$t('logout')}}</a>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  methods: {
    logOut () {
      localStorage.removeItem('Token')
      window.location = '/login'
    },
    tooggleMenu () {
      this.activeMenu ? 
      this.$store.commit("SET_DATA_MAIN", {type: "activeMenu", data: false}):
      this.$store.commit("SET_DATA_MAIN", {type: "activeMenu", data: true})
    }
  },
  computed: {
    ...mapState({
      title: state => state.main.title,
      activeMenu: state => state.main.activeMenu
    })
  }
}
</script>
