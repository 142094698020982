<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="type === 'pagination' ? items.results : items"
      :search="search"
      :loading="load"
      :options.sync="options"
      :server-items-length="items.count"
      :footer-props="{
        'items-per-page-text':'',
        'items-per-page-options': []
      }"
      item-key="id"
      class="elevation-1"
      loading-text=""
      :no-data-text="$t('noDataFound')"
      :no-results-text="$t('noDataFound')"
    >

      <template v-slot:item.user="{ item }">
        {{ item.user && item.user.first_name }}
        {{ item.user && item.user.last_name }}
      </template>

      <template v-slot:item.address="{ item }">
        {{ item.testing_center && item.testing_center.address }}
        {{ item.address && item.address }}
      </template>

      <template v-slot:item.status="{ item }">
        <v-btn
          :class="item.is_alive ? 'success' : 'error'"
          x-small>
          {{ item.is_alive && item.is_alive ? 'Online' : 'Offline' }}
        </v-btn>
      </template>

      <template v-slot:item.created_at="{ item }">
        {{ getShortDateFormat(item.created_at) }}
      </template>

      <template v-slot:item.updated_at="{ item }">
        {{ getShortDateFormat(item.updated_at) }}
      </template>

      <template v-slot:item.actions="{ item }">
        <router-link
          v-for="(link, key) in links"
          :key="key"
          :to="{ name: link.name, params: routerLinkParams(link, item) }"
          style="text-decoration: none">
          <v-btn
            class="ma-2"
            outlined
            x-small
            fab
            color="indigo">
            <v-icon>
              {{ link.icon }}
            </v-icon>
          </v-btn>
        </router-link>
      </template>

      <template v-slot:item.actionsFunction="{ item }">
        <v-btn
          class="ma-2"
          outlined
          x-small
          fab
          color="indigo"
          @click="swal(item.id)">
          <v-icon>
            mdi-beaker-remove-outline
          </v-icon>
        </v-btn>
      </template>

      <template v-slot:item.actionStands="{ item }">
        <div class="d-flex">
          <div class="mr-2">
              <!-- v-if="item.occupied_by && item.occupied_by.id === userID" -->
            <v-icon
             :disabled="item.hasOwnProperty('occupied_by') && item.occupied_by.id !== userID"
              v-if="item.occupied_by"
              @click="standActivity('release', 'standRelease', item.id)"
              color="error">
              mdi-pause-octagon-outline
            </v-icon>
              <!-- v-if="!('is_alive' in item) && !item.occupied_by" -->
            <v-icon
              v-if="!('is_alive' in item) && !item.occupied_by"
              @click="standActivity('occupy', 'standOccupied', item.id)"
              color="success">mdi-play-circle</v-icon>
          </div>
          <div
              :class="{'active' : showSettingsID === item.id}"
              class="table-actions" >
            <v-icon @click="showSettings(item.id)">mdi-cog-outline</v-icon>
            <div class="table-actions-subMenu">
              <ul>
                <template v-if="!('is_alive' in item)">
                  <li @click="openSettings(item, 'CalibrationWeight')">{{ $t('standSettings.calibrateWeight') }}</li>
                  <li @click="openSettings(item, 'CalibrationBrakeForce')">{{ $t('standSettings.calibrateBrakeForce') }}</li>
                  <li @click="openSettings(item, 'CalibrationPedalSensor')">{{ $t('standSettings.calibratePedalSensor') }}</li>
                  <li @click="openSettings(item, 'CalibrationHandbrakeSensor')">{{ $t('standSettings.calibrateHandbrakeSensor') }}</li>
                  <li @click="openSettings(item, 'SettingsSensor')">{{ $t('standSettings.testingSensor') }}</li>
                </template>
                <template v-if="('is_alive' in item) && offline || permissions.superadmin ">
                  <li @click="openSettings(item, 'SettingsReboot')">{{ $t('standSettings.standReboot') }}</li>
                  <li @click="openSettings(item, 'SettingsCheckConnect')">{{ $t('standSettings.checkConnectStand') }}</li>
                </template>
                <template v-if="!('is_alive' in item) && permissions.superadmin">
                  <li @click="openSettings(item, 'SettingsCharacteristics')">{{ $t('standSettings.settingsCharacteristics') }}</li>
                  <li @click="openSettings(item, 'SettingsInfo')">{{ $t('standSettings.standEdits') }}</li>
                  <li @click="openSettings(item, 'SettingsDeparture')">{{ $t('standSettings.departure') }}</li>
                </template>
              </ul>
            </div>
          </div>
        </div>
      </template>
    </v-data-table>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getShortDateFormat } from '@/mixins/main'
export default {
  name: 'Table',
  props: {
    headers: Array,
    items: Array,
    title: String,
    load: Boolean,
    pageCount: Number,
    querySearch: Function,
    links: Array,
    getDocument: Function,
    swal: Function,
    type: String,
    openSettings: Function,
    standActivity: Function,
    userID: Number,
    offline: Boolean
  },
  data () {
    return {
      options: { itemsPerPage: 10 },
      page: 1,
      search: '',
      timer: null,
      showSettingsID: '',
      getShortDateFormat
    }
  },
  computed: {
    ...mapState({
      lang: state => state.main.lang,
      permissions: state => state.permissions.permissions
    }),
  },
  watch: {
    search (value) {
      if (value.length > 3) {
        this.closeTimeout()
        this.setTimeoutQuerySearch()
      }
    },
    options: {
      handler (option) {
        if (this.getDocument) {
          if (option.page > this.items.current) {
            this.getDocument(this.items.next)
          } else if (option.page < this.items.current) {
            this.getDocument(this.items.previous)
          }
        }
      }
    },
  },
  methods: {
    showSettings (id) {
      if (this.showSettingsID !== id) this.showSettingsID = id
      else this.showSettingsID = ''
    },
    routerLinkParams (link, item) {
      switch (link.name) {
        case 'declarationDocument':
          return { number: item.number }
        default:
          return { id: item.id }
      }
    },

    changeQuerySet () {
      this.loading = true
      let params = {
        pageNumber: this.page,
        searchParam: this.search,
      }
      this.querySearch(params)
        .finally(() => {
          this.loadSearch = false
        })
      this.load = false
    },
    closeTimeout () {
      clearTimeout(this.timer)
    },
    setTimeoutQuerySearch () {
      this.loadSearch = true
      this.timer = setTimeout(() => {
        this.changeQuerySet()
      }, 3000)
    },
  },
  mounted () {}
}

</script>

<style scoped>
  .search {
    width: 25rem;
    flex: none;
  }
</style>
