import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home/Home.vue'

Vue.use(VueRouter)

const routes = [
  { path: '*',
    redirect: '/404'
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404.vue')
  },
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/login',
    name: 'authorization',
    component: () => import('@/views/Authorization/Authorization.vue')
  },
  {
    path: '/history',
    name: 'history',
    component: () => import('@/views/History/History.vue')
  },
  {
    path: '/history/declaration/:number',
    name: 'declarationDocument',
    props: {
      number: [Number, String]
    },
    component: () => import('@/views/History/Declarations/DeclarationDocument.vue')
  },
  {
    path: '/base',
    name: 'base',
    component: () => import('@/views/Base/Base.vue')
  },
  {
    path: '/laboratory',
    name: 'laboratory',
    component: () => import('@/views/Laboratory/Laboratory.vue')
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/Users/Users.vue')
  },
  {
    path: '/testing-centers',
    name: 'company',
    component: () => import('@/views/TestingCenter/TestingCenter.vue')
  },
  {
    path: '/testing-centers/:id',
    name: 'testingCenterInfo',
    component: () => import('../views/TestingCenter/TestingCenterInfo.vue'),
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('@/views/Test/Test.vue')
  },
  {
    path: '/stands',
    name: 'stands',
    component: () =>import('@/views/Stands/Stands.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/Settings/Settings.vue')
  },
  {
    path: '/versions',
    name: 'guideVersion',
    component: () => import('@/views/GuideVersions/GuideVersions.vue')
  },
  {
    path: '/result-protocol',
    name: 'result-protocol',
    component: () => import('@/views/ResultProtocol/ResultProtocol')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
