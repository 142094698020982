<template>
  <div class="dashboard">
    <div class="dashboard-statistic">
      <h3>{{$t('generalStatistics')}}</h3>
      <div class="dashboard-statistic__item">
        <span>{{$t('testReport')}}</span>
        <b>{{ stats.total.declarations }}</b>
      </div>
      <div class="dashboard-statistic__item">
        <span>{{$t('measurements')}}</span>
        <b>{{ stats.total.measurements }}</b>
      </div>
       <div class="dashboard-statistic__item">
        <span>{{$t('vehicles')}}</span>
        <b>{{ stats.total.vehicles }}</b>
      </div>
        <div class="dashboard-statistic__item">
        <span>{{$t('vehicleOwners')}}</span>
        <b>{{stats.total.users}}</b>
      </div>
    </div>
    <div class="dashboard-statistic">
      <h3>{{$t('statisticsToday')}}</h3>
      <div class="dashboard-statistic__item">
        <span>{{$t('declarations')}}</span>
        <b>{{ stats.daily.declarations }}</b>
      </div>
        <div class="dashboard-statistic__item">
        <span>{{$t('measurements')}}</span>
        <b>{{ stats.daily.measurements }}</b>
      </div>
      <div class="dashboard-statistic__item">
        <span>{{$t('vehicles')}}</span>
        <b>{{ stats.daily.vehicles }}</b>
      </div>
      <div class="dashboard-statistic__item">
        <span>{{$t('vehicleOwners')}}</span>
        <b>{{stats.daily.users}}</b>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'home',
  data() {
    return {

    }
  },
  computed: {
    ...mapState({
      stats: state => state.stand.standData.stats
    })
  },
  mounted()  {
    this.$store.commit('setDataTitle', this.$i18n.t('home'))
  },
}
</script>
