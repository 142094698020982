import Vue from 'vue'
import App from './App.vue'
import Vuelidate from 'vuelidate'
import VueGraph from 'vue-graph'

import router from '@/router'
import store from '@/store'
import i18n from '@/localization'

import VueSwal from 'vue-swal'
import vuetify from './plugins/vuetify'

import { api } from '@/mixins/api'
import { notify } from '@/mixins/notify'
import { validate } from '@/mixins/validation'

import Table from '@/components/layouts/Table.vue'

import { sync } from 'vuex-router-sync'

import './assets/sass/main.sass'


sync(store, router)

Vue.use(Vuelidate)
Vue.use(VueGraph)
Vue.use(VueSwal)

Vue.component('Table', Table)

Vue.config.productionTip = false
Vue.prototype.$api = api
Vue.prototype.$notification = notify
Vue.prototype.$validate = validate

Vue.mixin({
  methods: {
    $isEval: param => eval('this.'+param)
  }
})

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
