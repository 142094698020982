import { api } from '@/mixins/api'

export const USER = {
  state: () => ({
    isAuth: false,
    user: {},
    permissions: null,
    history: []
  }),
  mutations: {
    setDataUser (state, data) {
      state[data.prop] = data.value
    },
    setAuth (state, data) {
      state.isAuth = data
    },
  },
  actions: {
    setAuth ({ commit }, data) {
      let body = new FormData();
      body.append("username", data.username)
      body.append("password", data.password)
      let headers = {}
      console.log(body);
      return api.post('user/login/', body, headers)
        .then(response => {
          localStorage.setItem('Token', response.data.access_token)
          localStorage.setItem('session', response.data.session_uid)
          let checkToken = localStorage.getItem('Token')
          commit('setAuth', true)
          if (checkToken) window.location = '/'
          return Promise.resolve(response)
        })
        .catch(() => {
          commit('setAuth', false)
        })
    },

    async getUserInfo ({ commit }, id) {
      await api.get('admin/user')
        .then(response => {
          let value = response.data.filter((item) => item.id === id)
          commit('setDataUser', { prop: 'user', value: value[0] })
          return response
        })
    },

    async updateUser ({ commit }, body) {
      return await api.put(`admin/user/`, JSON.stringify(body)).then(response => {
        response.status === 'success' && commit('setDataUser', { prop: 'user', value: response.data })
        return Promise.resolve(response.status)
      })
    },

  }
}
