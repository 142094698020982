<template>
  <div :class="['nav', {'active-menu': activeMenu}]">
    <div class="nav-logo">
      <img src="@/assets/logo.svg" alt="">
    </div>
    <a
      href="/test"
      class="nav-btn btn-primary">
			{{$t('startMeasuring')}}
		</a>

    <div class="nav-title">{{$t('mainMenu')}}</div>

    <ul>
      <li :class="checkActivePage('home')">
        <router-link to="/">{{$t('home')}}</router-link>
      </li>
      <li :class="checkActivePage('history', 'declaration-info')">
        <router-link to="/history">{{$t('measurementHistory')}}</router-link>
      </li>
      <!-- <li><router-link to="/laboratory">Стенди</router-link></li> -->
      <li v-if="
        permissions.superadmin ||
        permissions.admin"
        :class="checkActivePage('users')"
        >
        <router-link to="/users">{{$t('users')}}</router-link>
      </li>
      <li :class="checkActivePage('stands')">
        <router-link to="/stands">{{$t('stands')}}</router-link>
      </li>
      <!-- <li><a href="#">Записи</a></li> -->
      <!-- <li><a href="#">Отчеты</a></li> -->
    </ul>
    <div
      v-if="permissions.superadmin"
    >
      <div class="nav-title">{{$t('mainOffice')}}</div>
      <ul>
        <li :class="checkActivePage('company')">
          <router-link to="/testing-centers">{{$t('testingCenters')}}</router-link>
        </li>
        <li :class="checkActivePage('base')">
          <router-link to="/base">{{$t('database')}}</router-link>
        </li>
      </ul>
    </div>
    <div class="productVersion">
      v.0.0.1
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Menu',
  computed: {
    ...mapState({
      user: state => state.user,
      permissions: state => state.permissions.permissions,
      activeMenu: state => state.main.activeMenu
    })
  },
  methods: {
    checkActivePage (...current) {
      if (current.includes(this.$route.name)) return 'activeLink'
      else return ''
    }
  }
}
</script>

<style>
  .activeLink a {
    color: #1976d2 !important;
    background-color: rgba(51, 102, 255, 0.08);
    border-left: 5px solid;
    border-radius: 0 !important;
  }

  .nav ul li a {
    border-radius: 0 !important;
  }

  .nav-title {
    border-top: none !important;
  }
  .nav-btn.btn-primary {
    display: flex !important;
    margin: 0px 20px 10px 20px !important;
  }
</style>
