<template>
  <v-app>
    <div v-if="load" class="app">
      <Menu v-if="load && !loginPage && !notFoundPage"/>
      <div class="main">
        <TopBar v-if="!loginPage && !notFoundPage"/>
        <router-view class="content"/>
      </div>
    </div>
    <!-- <div v-if="!load" class="app">
      <div class="d-flex" style="flex: 1; background: #fff; align-items: center; justify-content: center; flex-direction: column">
        <img src="@/assets/images/down.png" alt="">
      </div>
    </div> -->
  </v-app>
</template>

<script>
import Menu from '@/components/layouts/Menu'
import TopBar from '@/components/layouts/TopBar'
import { mapState } from 'vuex'

export default {
  name: 'App',
  components: {
    Menu,
    TopBar
  },
  data () {
    return {
      loginPage: false,
      notFoundPage: false
    }
  },
  mounted () {
    if (window.location.pathname === '/login') {
      this.loginPage = true
    }
    this.$store.dispatch('getPermission')
    this.$store.dispatch('setStandData')
      .then(() => this.$store.dispatch('getUserInfo', this.userId))
    this.$store.dispatch('setStandOffline')

    
  },
  computed: {
    ...mapState({
      load: state => state.permissions.load,
      userId: state => state.stand.standData.user_id
      
    })
  }
};
</script>

<style>
</style>
