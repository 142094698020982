import iziToast from 'izitoast'
import 'izitoast/dist/css/iziToast.min.css'

iziToast.settings({
  close: true,
  pauseOnHover: true,
  timeout: 3000,
  progressBar: true,
  layout: 2,
  position: 'topRight'
})

class NOTIFY {
  info = (text) => {
    return iziToast.info({
      title: 'Інфо!',
      message: text,
      icon: 'icon-person',
    })
  }
  success = (text) => {
    return iziToast.success({
      title: 'Успіх!',
      message: text,
      icon: 'icon-person',
    })
  }
  warning = (text) => {
    return iziToast.warning({
      title: 'Увага!',
      message: text,
      icon: 'icon-person',
    })
  }
  error = (text) => {
    return iziToast.error({
      title: 'Помилка!',
      message: text,
      icon: 'icon-person',
    })
  }
}

export const notify = new NOTIFY()
