class SOCKET {
  constructor() {
    // this.status = false
    this.socketURL = process.env.VUE_APP_SOCKET + localStorage.getItem('session')
    console.log(this.socketURL)
    this.connection = null // new WebSocket(this.socketURL)
  }

  async connect() {
    this.connection = new WebSocket(this.socketURL)
    // this.connection.onopen = await function (event) {
    //   // this.status = true
    //   console.log(event)
    // }
  }
  send(body) {
    return new Promise((resolve) => {
      console.log(this.connection)
      console.log(this.connection.readyState)
      // if ([0, 1].includes(this.connection.readyState)) this.connection.send(body)

      this.connection.onopen = (state) => {
        console.log(state)
        console.log('open - send')
        this.connection.send(body)
      }

      if ([1].includes(this.connection.readyState)) this.connection.send(body)

      this.connection.onmessage = (response) => {
        resolve(response)
      }
      this.connection.onerror = (error) => {
        console.log(error)
        resolve(error)
      }
      this.connection.onclose = (error) => {
        console.log(error)
        resolve(error)
      }
    });
  }

  async stop() {
    this.connection && this.connection.close()
  }
}
export const socket = new SOCKET();
class API {
  mainUrl = process.env.VUE_APP_API
  parseJSON = (response) => {
    return response.json()
      .then((data) => data)
      .catch((e) => e)
  }

  fetch(url, options, body) {
    const token = localStorage.getItem('Token')

    if (!options.headers) {
      options.headers = {}
      options.headers.Accept = 'application/json'
      options.headers['Content-Type'] = 'application/json'
    }

    if (token) {
      options.headers.Authorization = `Bearer ${token}`
    }

    if (body) {
      options.body = body
    }

    console.log(options)


    const response = fetchRequest(this.mainUrl + url, options)
    return response
  }

  async fetchPhoto(url, options, formData) {
    const token = localStorage.getItem('Token')

    if (!options.headers) {
      options.headers = {}
    }
    if (token) {
      options.headers.Authorization = `Bearer ${token}`
    }
    options.body = formData

    if (options.method === 'GET') {
      options.headers['Access-Control-Request-Headers'] = '*'
      return fetch(this.mainUrl + url)
        .then(response => {
          switch (true) {
            case response.status >= 200 && response.status <= 299:
            case response.status === 400:
              return response.blob().then(images => {
                return URL.createObjectURL(images)
              })
          }
        })
    } else {
      return fetch(this.mainUrl + url, options)
        .then(response => {
          switch (true) {
            case response.status >= 200 && response.status <= 299:
            case response.status === 400:
              return response.json().then(data => {
                switch (response.status) {
                  case 200:
                    return { status: 'success', data: data }
                  case 201: // create any
                    return { status: 'created', data: data }
                  case 204: // delete
                    return { status: 'deleted' }
                  case 400:
                    return { status: 'error', data: data }
                }
              })
            case response.status === 401:
              if (window.location.pathname !== '/login') {
                socket.stop()
                localStorage.removeItem('Token')
                window.location = '/login'
              }
              break
            case response.status === 404:
              return { status: 'not found' }
            case response.status === 500:
              return { status: 'server error' }
            case response.status === 502:
              return { status: 'bad gateway' }
          }
        })
        .catch((error) => {
          console.error(error)
          if (window.location.pathname !== '/login' && error.status === 401) {
            socket.stop()
            localStorage.removeItem('token')
            window.location = '/login'
          }
        })
    }
  }

  async fetchDoc(url, options, body) {
    const token = localStorage.getItem('Token')

    if (!options.headers) {
      options.headers = {}
    }
    if (token) {
      options.headers.Authorization = `Bearer ${token}`
    }
    if (body) {
      options.body = body
    }

    return fetch(this.mainUrl + url, options)
      .then(response => {
        switch (true) {
          case response.status >= 200 && response.status <= 299:
          case response.status === 400:
            return response.blob()
        }
      })
  }

  get(url, body) {
    const options = {
      method: 'GET',
      body: body
    }
    return this.fetch(url, options)
  }

  post(url, body, headers = null) {
    const options = {
      method: 'POST',
      headers
    };
    return this.fetch(url, options, body)

  }

  put(url, body) {
    const options = { method: 'PUT' }
    return this.fetch(url, options, body)
  }

  delete(url, body) {
    const options = { method: 'DELETE' }
    return this.fetch(url, options, body)
  }

  patch(url, body) {
    const options = { method: 'PATCH' }
    return this.fetch(url, options, body)
  }

  getPhoto(url) {
    const options = {
      method: 'GET'
    };
    return this.fetchPhoto(url, options)
  }

  postPhoto(url, formData) {
    const options = {
      method: 'POST'
    };
    return this.fetchPhoto(url, options, formData)
  }

  getDoc(url) {
    const options = {
      method: 'GET'
    };
    return this.fetchDoc(url, options)
  }
}

export const fetchRequest = (url, options) => {
  return fetch(url, options)
    .then(response => {
      switch (true) {
        case response.status === 101:
        case response.status === 204:
        case response.status === 205:
          return { status: 'deleted' }
        case response.status === 304:
          return { status: 'ok' }
        case response.status >= 200 && response.status <= 299:
        case response.status === 400:
          return response.json().then(data => {
            switch (response.status) {
              case 200:
                return { status: 'success', data: data }
              case 201: // create any
                return { status: 'created', data: data }
              case 204: // delete
                return { status: 'deleted' }
              case 400:
                return { status: 'error', data: data }
            }
          })
        case response.status === 401:
          if (window.location.pathname !== '/login') {
            socket.stop()
            localStorage.removeItem('Token')
            window.location = '/login'
          }
          break
        case response.status === 404:
          // hotify(_this, 'Помилка!', 'Спробуйте знову', 'error')
          return { status: 'not found' }
        case response.status === 500:
          // hotify(_this, 'Помилка!', 'Спробуйте знову', 'error')
          return { status: 'server error' }
        case response.status === 502:
          // hotify(_this, 'Помилка!', 'Спробуйте знову', 'error')
          return { status: 'bad gateway' }
      }
    })
    .catch((error) => {
      if (window.location.pathname !== '/login' &&
        error.status === 401) {
        socket.stop()
        localStorage.removeItem('token')
        window.location = '/login'
      }
    })
}





export const api = new API();
