import i18n from '@/localization'
import { required, minLength, maxLength, helpers } from 'vuelidate/lib/validators'

import { USER_RULES } from "./validationRules/userRules"

// const alphabet = helpers.regex('alpha', /^[a-zA-Zа-щА-ЩЬьЮюЯяЇїІіЄєҐґ'\-\s]*$/);
// const NUMBER_REGEX = helpers.regex('alpha', /^[,.0-9]*$/);
const LICENSE_PLATE_REGEX = helpers.regex('alpha', /^[a-zA-Zа-щА-ЩЬьЮюЯяЇїІіЄєҐґ,0-9]*$/);
const VERSION_REGEX = helpers.regex('alpha', /^[.0-9]*$/);

// const CURRENT_DATA = new Date().getFullYear();

class VALIDATION {
  userRules = USER_RULES

  editStandInfo = {
    item: {
      address: { required },
      name: { required }
    }
  }

  tcObj = {
    tcObj: {
      address: { required },
      name: { required }
    }
  }

  addStand = {
    addStand: {
      id: { required }
    }
  }

  vehicleObj = {
    vehicleObj: {
      manufacturer_id: { required },
      model_id: { required },
      vehicle_type_id: { required },
      category_id: { required },
      issue_year: { required },
      axis_number: { required },
      mass: { required },
      fuel_type_id: { required },
      car_license_plate: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(8),
        LICENSE_PLATE_REGEX
      },
      engine_volume: { required },
      color_id: { required },
      odometr: { required }
    }
  }

  standID = {
    standID: { required }
  }

  ownerID = {
    ownerID: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(10)
    }
  }

  ownerObj = {
    ownerObj: {
      name: { required }
    }
  }

  numberChassis = {
    numberChassis: {
      required,
      minLength: minLength(5),
      maxLength: maxLength(17)
    }
  }
  entrepreneur = {
    userObj: {
      first_name: { required },
      last_name: { required },
      username: { required },
    },
  }

  versions = {
    version: {
      required,
      VERSION_REGEX,
      minLength: minLength(5),
      maxLength: maxLength(10)

    }
  }

  protocolResult = {
    car_parameters: {
      numberChassis: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(17)
      },
      category: { required },
      manufacturer: { required },
      model: { required },
      car_license_plate: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(8),
        LICENSE_PLATE_REGEX
      },
      odometr: {
        required,
        VERSION_REGEX
      },
      fuel_type_id: { required },
      turbine_compressor: { required },
      reequipment_doc_date: { required },
      reequipment_doc_number: { required },
      essenceReequipment: { required },
      numberCylinders: {
        required,
        VERSION_REGEX
      },
      ecologicalLeve: { required },
      color: { required },
      purpose: { required },
      air_temperature: { required },
      humidity: { required },
      atmospheric_pressure: { required },
    },
    tire_tread: {
      tire_tread_one: { required },
      tire_tread_two: { required },
      tire_tread_three: { required }
    }
  }

  required (model) {
    const errors = []
    if (!model.$dirty) return errors
    !model.required && errors.push(i18n.t('notify.requiredField'))
    return errors
  }

  length (model) {
    const errors = []
    if (!model.minLength || !model.maxLength) errors.push(i18n.t('notify.invalidFormat'))
    return errors
  }

  value (model) {
    const errors = []
    if (!model.minValue || !model.maxValue) errors.push('invalidFormat')
    return errors
  }

  regexp (model, regex) {
    const errors = []
    if (model && !model[regex]) errors.push('invalidFormat')
    return errors
  }

  sameAs (model) {
    const errors = []
    if (!model.sameAs) errors.push(i18n.t('passNotConfirm'))
    return errors
  }

  LICENSE_PLATE_REGEX (model) {
    const errors = []
    if (!model.LICENSE_PLATE_REGEX) errors.push(i18n.t('notify.invalidFormat'))
    return errors
  }

  VERSION_REGEX (model) {
    const errors = []
    if (!model.VERSION_REGEX) errors.push(i18n.t('notify.invalidFormat'))
    return errors
  }

}

export const validationField = (field, type) => {
  let errors = []
  type.forEach(item => errors.push(...validate[item](field)) )
  return errors
}

export const validate = new VALIDATION ()
