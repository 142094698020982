import { api } from '@/mixins/api'

export const HISTORY = {
  state: () => ({
    declarations: {
      results: []
    }
  }),
  mutations: {
    SET_DECLARATIONS_DATA(state, data) {
      state[Object.entries(data)[0][0]] = Object.entries(data)[0][1]
    },
    UPDATE_DECLARATIONS_DATE (state, data) {
      let i = 0
      let prop = Object.entries(data)[0][0]
      let val = Object.entries(data)[0][1]
      state[prop].results.forEach((item, index) => {
        if (item.id === val.id) i = index
      })
      state[prop].results.splice(i, 1)
      state[prop].results.push(val)
    },
  },
  actions: {
    async getDeclarations ({ commit }, params) {
      let querySet = new URLSearchParams()
      querySet.append('limit', 10)
      querySet.append('page', params.link ? params.link : 1)
      params = JSON.stringify(params)
      await api.post(`dashboard/declaration/?${querySet}`, params)
        .then(response => {
          console.log(response)
          commit('SET_DECLARATIONS_DATA', { declarations: response.data })
        })
    },
    async getDeclaration ({ commit }, id) {
      let body = JSON.stringify({
        declaration_id: id
      })
      let headers
      return await api.post('dashboard/declaration', body, headers )
        .then(response => {
          console.log(id);
          console.log(response);
          commit('UPDATE_DECLARATIONS_DATE', { declarations: response.data.results[0] })
          return response.data
        })
    },
  },
  getters: {
    declarationById: state => id => state.declarations.results.filter(declaration => declaration.number === id)
  }
}
