import store from '@/store'
import router from '@/router'

const inProcess = [5,12,]
const expired = [1,13,15,16,17,18]
const warning = [4,6,9,]
const reject = [2,3,8,9,]
const valid = [7,11,14]
const verification = []
const info = []


export const getStatus = (statusId) => {
  switch (true) {
    case (inProcess.includes(statusId)):
      return 'blue'
    case (expired.includes(statusId)):
      return 'red'
    case (warning.includes(statusId)):
      return 'orange'
    case (reject.includes(statusId)):
      return 'purple'
    case (valid.includes(statusId)):
      return 'green'
    case (verification.includes(statusId)):
      return 'yellow'
    case (info.includes(statusId)):
      return 'blue'
  }
}

export const getDecision = (statusId) => {
  switch (true) {
    case (statusId === 2):
      return 'red'
    case (statusId === 1):
      return 'green'
  }
}

export const getDate = (date) => {
  date = new Date(date)
  let year = date.getFullYear()
  let month = String(date.getMonth()).padStart(2, '0')
  let day = String(date.getDay()).padStart(2, '0')
  let hours = date.getHours()
  let minutes = String(date.getMinutes()).padStart(2, '0')
  return `${year}-${month}-${day} ${hours}:${minutes}`
}

export const getDateFormat = (date) => {
  if (date) return date.split('-').reverse().join('.')
}

export const getShortDateFormat = (date) => {
  if (date) return getDateFormat(date.split('T')[0])
}

export const setDateAddYears = (years) => {
  let year = new Date().getFullYear()+years
  let month = new Date().getMonth()
  let day = new Date().getDay()
  return `${year}-${month}-${day}`
}

export const setDateSubtractYears = (years) => {
  let year = new Date().getFullYear()-years
  let month = new Date().getMonth()
  let day = new Date().getDay()
  return `${year}-${month}-${day}`
}

export const back = (type) => {
  let  link = store.state.route.from.name ? store.state.route.from.name : type
  router.push({ name: link })
}

export const getTypeVessel = (vessel) => {
  let vesselObj = store.getters.typeVesselByID(Number(vessel))
  return vesselObj[0]
}
export const getModeNavigation = (mode) => {
  let modeObj = store.getters.modeNavigationByID(Number(mode))
  return modeObj[0]
}
export const getInstitutionETIName = (institution) => {
  let institutionObj = store.getters.institutionETIByID(institution)
  return institutionObj.length ? institutionObj[0].name_ukr : ''
}
export const getCourseETIName  = (course) => {
  let courseObj = store.getters.courseETIByID(course)
  return courseObj.length ? courseObj[0].name_ukr : ''
}
export const getStatusName = (status) => {
  let statusObj = store.getters.status(status)
  return statusObj.length ? statusObj[0].name_ukr : ''
}
export const getDecisionName = (decision) => {
  let decisionObj = store.getters.decisionByID(decision)
  return decisionObj ? decisionObj[0].name_ukr : ''
}
export const getRankName = (rank) => {
  let rankObj = store.getters.rankByID(rank)
  return rankObj.length ? rankObj[0].name_ukr : ''
}
export const getPositionName = (position) => {
  let positions = []
  position.map(item => {
    let positionObj = store.getters.positionByID(item)
    positions.push(positionObj[0].name_ukr)
  })
  return positions.join(' ,')
}
export const getPositionMedicalName = (position) => {
  let positionObj = store.getters.positionMedicalByID(position)
  return positionObj.length ? positionObj[0].name_ukr : ''
}
export const getBranchName = (branch) => {
  let branchObj = store.getters.branchByID(branch)
  return branchObj.length ? branchObj[0].name_ukr : ''
}
export const getDoctorName = (doctor) => {
  let doctorObj = store.getters.doctorByID(doctor)
  return doctorObj.length ? doctorObj[0].full_name : ''
}
export const getLimitationName = (limitation) => {
  let limitationObj = store.getters.limitationByID(limitation)
  return limitationObj.length ? limitationObj[0].name_ukr : ''
}
export const getMedicalInstitutionNameByDoctor = (doctor) => {
  let doctorObj = store.getters.doctorByID(doctor)[0]
  return doctorObj.length ? doctorObj[0].name_ukr : ''
}
export const getCountryName = (country) => {
  let countryObj = store.getters.countryByID(country)
  return countryObj.length ? countryObj[0].name_ukr : ''
}
export const getRegionName = (region) => {
  let regionObj = store.getters.regionByID(region)
  return regionObj.length ? regionObj[0].name_ukr : ''
}
export const getCityName = (city) => {
  let cityObj = store.getters.cityByID(city)
  return cityObj.length ? cityObj[0].name_ukr : ''
}
export const getSexName = (sex) => {
  let sexObj = store.getters.sexByID(sex)
  return sexObj.length ? sexObj[0].name_ukr : ''
}
export const getCommissioner = (commissioner) => {
  let commissionerObj = store.getters.commissionerByID(commissioner)
  return commissionerObj.length ? commissionerObj[0].fullname : ''
}
