import { api } from '@/mixins/api'
import { notify } from '@/mixins/notify'

export const STAND = {
  state: () => ({
    step: 1,
    standData: {},
    standDataOffline: [],
    standDataNew: [],
    standUnreg: [],
    model: [],
    declaration: ''
  }),
  mutations: {
    SET_DATA (state, data) {
      state[data.prop] = data.value
    },
    UPDATE_TESTING_CENTER (state, data) {
      let i = 0
      let prop = Object.entries(data)[0][0]
      let val = Object.entries(data)[0][1]
      state[prop].testing_centers.forEach((item, index) => {
        if (item.id === val.id) {
          i = index
          state[prop].testing_centers.splice(i, 1)
        }
      })
      state[prop].testing_centers.push(val)
    },
  },
  actions: {
    setStandData ({ commit }) {
      return api.get('stand/').then(response => {
        commit('SET_DATA', { prop: 'standData', value: response.data })
      })
    },
    setStandOffline ({ commit }) {
      return api.get('stand/offline').then(response => {
        commit('SET_DATA', { prop: 'standDataOffline', value: response.data})
      })
    },
    getModel ({ commit }, data) {
      return api.get('stand/models?man_uid=' + data).then(response => {
        commit('SET_DATA', { prop: 'model', value: response.data.models })
      })
    },
    setTestingCenter ({ commit }, body) {
      body = JSON.stringify(body)
      return api.post('admin/testing-center', body)
        .then(response => {
          commit('UPDATE_TESTING_CENTER', { standData: response.data })
          response && notify.success('Центр тестування успішно створено')
        })
    },
    updateTestingCenter ({ commit }, data) {
      let body = JSON.stringify({
        id: data.id,
        name: data.name,
        address: data.address
      })
      return api.patch('admin/testing-center', body)
        .then(response => {
          commit('UPDATE_TESTING_CENTER', { standData: response.data })
          response &&  notify.success('Центр тестування успішно відредаговано')
        })
    },
    setTestingCenterStand ({ dispatch }, body) {
      return api.put(`admin/stand/register/?${body}`)
        .then(response => {
          response && dispatch('setStandData')
          response && notify.success('Центр тестування успішно відредаговано')
        })
    },
    getUnregStand ({ commit }) {
      api.get('admin/stand/unreg')
        .then(response => {
          response && commit('SET_DATA', { prop: 'standUnreg', value: response.data })
        })
    },
    removeStand ({ dispatch }, id) {
      api.put(`admin/stand/unreg/?stand_id=${id}`)
        .then(response => {
          response && dispatch('setStandData')
          response && notify.success('Стенд успішно видалено')
        })
    },
    setStandActivity ({ dispatch }, data) {
      api.get(`action/${data.id}/${data.type}`)
        .then(response => {
          response && dispatch('setStandData')
          response && notify.success('Підключення до стенду змінено')
        })
    }
  },
  getters: {
    testingCenterById: state => id => state.standData.testing_centers.filter(testingCenter => testingCenter.id === id)
  }
}
