
export const MAIN = {
  state: () => ({
    lang: localStorage.getItem('language') || 'ua',
    title: '',
    langField: 'name_ukr',
    activeMenu: false
  }),
  mutations: {
    setDataTitle (state, data) {
      state.title = data
    },
    SET_DATA_MAIN (state, data) {
      state[data.type] = data.data
    }
  }
}
