import { api } from '@/mixins/api'
import { notify } from '@/mixins/notify'
// import i18n from '../../localization'

export const MEASUREMENT = {
  state: () => ({
    step: 1,
    standID: '',
    numberChassis: 1,
    stage: [],
    loading: [],
    measurement: [],
    weight: 0,
    odometr: '',
    isSteppedToEnd: false,
    vehicleObj: {
      standart: ' ',
      created_by_id: 1,
    },
    ownerObj: {
      name: '',
      owner_code: '',
      is_legal_person: false
    },
    declaration: '',
    results: [],
    status: 'decline',
    actions: {
      isCheck: false,
      isStatus: true,
      isConnect: false,
      isReady: false,
      isTesting: false,
      isNoConnect: false,
      isActive: false
    },
  }),
  mutations: {
    SET_DATA_MEASUREMENT (state, data) {
      state[data.prop] = data.value
    },
    SET_DATA_MEASUREMENT_NESTING (state, data) {
      state[data.prop][data.nesting] = data.value
    },
    PUSH_MEASUREMENT (state, data) {
      state[data.prop].push(data.value)
    },
    SET_LOADING (state, data) {
      if (state.loading.includes(data)) state.loading = state.loading.filter(item => item !== data)
      else state.loading.push(data)
    },
    SET_ACTIONS_STATUS (state, data) {
      state.actions[data.prop] = data.type
      state.actions.isActive = data.isActive
    },
  },
  actions: {
    stepControl ({ commit, state }, step) {
      commit('SET_DATA_MEASUREMENT', { prop: 'step', value: step ? state.step + 1 : state.step - 1 })
    },
    setNumberChassis ({ commit, dispatch }, data) {
      commit('SET_DATA_NESTING', { prop: 'vehicleObj', nesting: 'vin', value: data })
      dispatch('checkVehicle')
    },
    checkVehicle ({ commit, state }) {
      Object.keys(state.vehicleObj).forEach(i => { if (i !== 'vin' && i !== 'created_by_id') state.vehicleObj[i] = '' })
      return api.get(`stand/vehicle/?vin=${state.vehicleObj.vin}`)
        .then(response => {
          response.data.id && commit('SET_DATA_MEASUREMENT', { prop: 'vehicleObj', value: response.data })
        })
    },
    getVehicleFromId ({ state, commit }) {
      let params = new URLSearchParams()
      params.set('id', state.vehicleObj.id)
      return api.get(`stand/vehicle/?${params}`)
        .then(response => {
          response && commit('SET_DATA_MEASUREMENT', { prop: 'vehicleObj', value: response.data })
        })
    },
    setVehicle ({ state, commit }, type) {
      let _odometr = state.vehicleObj.odometr
      api[type]('stand/vehicle/', JSON.stringify(state.vehicleObj))
        .then(response => {
          response && commit('SET_DATA_MEASUREMENT', { prop: 'vehicleObj', value: { _odometr,...response.data } })
        })
    },
    setOwnerID ({ commit, state, dispatch }, owner_code ) {
      let owner = state.ownerObj
      Object.keys(owner).forEach(i => owner[i] = '')
      owner.is_legal_person = false
      owner.owner_code = owner_code
      commit('SET_DATA_MEASUREMENT', { prop: 'ownerObj', value: owner })
      dispatch('checkOwner')
    },
    checkOwner ({ commit, state }) {
      api.get(`stand/owner/?registry_code=${state.ownerObj.owner_code}`)
        .then(response => {
          response.data[0] && commit('SET_DATA_MEASUREMENT', { prop: 'ownerObj', value: response.data[0] })
        })
    },
    async setOwner ({ commit, state, dispatch }, type) {
      await api[type]('stand/owner', JSON.stringify(state.ownerObj))
        .then(response => {
          console.log(response)
          response.data && commit('SET_DATA_MEASUREMENT', { prop: 'ownerObj', value: response.data })
          dispatch('createDeclaration')
        })
    },
    createDeclaration ({ state, commit }) {
      let today = new Date().toISOString()
      let date = today.split('T')[0]
      let body = JSON.stringify({
        stand_serial: state.standID,
        vehicle: {
          vehicle_id: state.vehicleObj.id
        },
        odometr_value: 0,
        owner_id: state.ownerObj.id,
        vehicle_registration_date: date
      })
      api.post('stand/declaration', body)
        .then(response => {
          commit('SET_DATA_MEASUREMENT', { prop: 'declaration', value: response.data.declaration_number })
        })
    },

    // MEASUREMENT

    changeNumberChassis ({ commit }, id) {
      commit('SET_DATA_MEASUREMENT', { prop: 'numberChassis', value: id })
      commit('SET_DATA_MEASUREMENT', { prop: 'weight', value: 0 })
      commit('SET_ACTIONS_STATUS', { prop: 'isStatus', type: true })
      commit('SET_ACTIONS_STATUS', { prop: 'isReady', type: false, isActive: false })
    },

    checkStatus ({ state }, id) {
      return api.get(`action/${ id ? id : state.standID }/check`)
    },

    runSensorTest ({ dispatch }, data) {
      return new Promise ((resolve) => {
        let params = new URLSearchParams()
        params.append('platform', data.side)
        let timeout = false
        api.get(`action/${data.id}/sensor_test/${data.type}?${params}`)
        let cheking = setInterval(() => {
          dispatch('checkStatus', data.id)
            .then(response => {
              if (response.data.result[0].result) {
                timeout = true
                clearInterval(cheking)
                resolve(response.data.result[0])
              }
            })
        }, 2000)
        setTimeout(() => {
          if (!timeout) {
            clearInterval(cheking)
            resolve(false)
          }
        },30000)
      })
    },

    async connectStand ({ commit, state, dispatch }) {
      commit('SET_ACTIONS_STATUS', { isActive: true })
      commit('SET_LOADING', 'BTN-checkStatusStandInfo')
      function stopConnect () {
        commit('SET_ACTIONS_STATUS', { isActive: false })
        commit('SET_LOADING', 'BTN-checkStatusStandInfo')
      }
      let isActive = false
      let responseFromStatus = await api.get(`action/${state.standID}/status`)
      if (!responseFromStatus) {
        //   // notify.error(i18n.t('theStandOccupied')) 
        notify.error('Стенд зайнятий іншим користувачем') 
        stopConnect()
        return
      } 
      let cheking = setInterval(() => {
        dispatch('checkStatus')
          .then(response => {
            let data = response.data.result?.[0]
            if (data?.type === 'status') {
              clearInterval(cheking)
              commit('SET_ACTIONS_STATUS', { prop: 'isReady', type: true })
              commit('SET_ACTIONS_STATUS', { prop: 'isStatus', type: false, isActive: false })
              commit('SET_DATA_MEASUREMENT', { prop: 'weight', value: data.weight })
              commit('SET_LOADING', 'BTN-checkStatusStandInfo')
              isActive = true
            }
          })
      }, 500)
      setTimeout(() => {
        if (!isActive) {
          clearInterval(cheking)
          notify.error('Стенд не відповідає, спробуйте пізніше')
          stopConnect()
        }
      }, 60000)
    },

    startMeasurement ({ commit, state, dispatch }) {
      let isFinish = false
      commit('SET_ACTIONS_STATUS', { prop: 'isTesting', type: true })
      commit('SET_ACTIONS_STATUS', { prop: 'isReady', type: false, isActive: true })
      function stopTesting () {
        commit('SET_ACTIONS_STATUS', { prop: 'isStatus', type: true })
        commit('SET_ACTIONS_STATUS', { prop: 'isTesting', type: false, isActive: false })
      }
      function checkStatusInterval () {
        if (state.actions.isTesting) {
          dispatch('checkStatus')
            .then(response => {
              let data = response.data?.result
              if (data?.[0]?.stage !== 'Finish') data?.forEach(e => commit('PUSH_MEASUREMENT', { prop: 'stage', value: e.stage }))
              else {
                isFinish = true
                data.numberChassis = state.numberChassis
                commit('PUSH_MEASUREMENT', { prop: 'measurement', value: response.data.result[0].results } )
                stopTesting()
                commit('SET_DATA_MEASUREMENT', { prop: 'stage', value: [] })
              }
            })
        }
      }
      api.get(`action/${state.standID}/test/${state.declaration}/${state.numberChassis}`)
        .then(() => {
          const WAITING_TIME = 90000
          let cheking = setInterval(checkStatusInterval, 500)
          !state.actions.isTesting  && clearInterval(cheking)
          setTimeout(() => {
            if (!isFinish) {
              commit('SET_DATA_MEASUREMENT', { prop: 'stage', value: [] })
              clearInterval(cheking)
              stopTesting()
              notify.error('Стенд не відповідає, спробуйте пізніше')
            }
          }, WAITING_TIME)
        })
    }
  },
  getters: {
    getLoadingByName: state => type => state.loading.includes(type),
  }
}
