import { api } from '@/mixins/api'

export const VERSION = {
  state: () => ({
    edited: {}
  }),
  mutations: {
    SET_VERSION (state, data) {
      state[data.prop] = data.value
    },
  },
  actions: {
    async getVersion () {
      return await api.get("admin/version")
    },
    async addVersion (_,data) {
      return await api.post("admin/version", JSON.stringify(data)).then((r) => {
        if (!r) return Promise.reject('exist')
        if (r.status === 'success') return Promise.resolve(r)
      })
     },
    async removeVersion (_,id) {
      const params = new URLSearchParams()
      params.set('id', id)
      return await api.delete(`admin/version?${params}`)
    },
    async updateVersion (_,data) {
      const params = new URLSearchParams()
      params.set('id', data.id)
      return await api.put(`admin/version?${params}`, JSON.stringify(data)).then((r) => {
        if (r.status === 'success') return Promise.resolve(r)
      })
    }
  }
}
